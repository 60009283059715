export enum CLIENTS {
  SAMARCO = "samarco",
  TAGNA = "tagna",
}

export enum DOMAIN {
  SAMARCO = "samarco.com",
  SAMARCO_PROJETOS = "samprojetos.com",
  TAGNA = "tagna.com.br",
  NEXUM = "nexum.digital",
}

export enum ROLES {
  ADM = "g_sec_dvr_health_adm",
  BALANCO = "g_sec_dvr_health_balance",
  INSTRUMENTISTA = "g_sec_dvr_health_instrumentista",
  OPERACIONAL = "g_sec_dvr_health_operacional",
  DEV = "dev",
}

export enum COMPANY_UUID {
  SAMARCO = "e185fef6-61dd-497d-a164-beb561b63b06",
  TAGNA = "e185fef6-61dd-497d-a164-beb561b63b06",
}

export const PERMITED_ROLES_HEADER = "Permited-Roles";