import React, { useState, useEffect, useCallback } from 'react';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useLocation } from 'react-router-dom';

import {
  Backdrop,
  Fade,
  Tooltip,
  Select,
  MenuItem,
  InputBase,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { DateRange as DateRangeIcon, ArrowRightAlt } from '@material-ui/icons';
import {
  toDate,
  format,
  setHours,
  setMinutes,
  getHours,
  getMinutes,
} from 'date-fns';

import { Creators } from '../../store/actionCreators';
import { DataAnalysisName } from '../../store/dataAnalysis/types';
import { AppState } from '../../store/reducers';
import { ReportNames } from '../../store/reports/types';
import { DateRange, DateRangePicker } from '../Pickers/RangeDatePicker';
import { TimeRange } from '../Pickers/RangeDatePicker/types';

import { supportedDevices } from '../../styles/supportedDevices';
import {
  DesktopContainer,
  Button,
  CustomModal,
  TooltipSpan,
  MobileContainer,
} from './styles';

export interface TimePeriod {
  startTime: string | number | null;
  endTime: string | number | null;
}

export interface DateFilterItem {
  id: string;
  content: string | JSX.Element;
  tooltip: string;
  onClick?: () => void;
}

export interface DateFilterProps {
  fixedFilters: DateFilterItem[];
  initialValue?: string;
  breakpoint?: string;
  timePeriodState?: 'treeHealth' | 'reports' | 'dataAnalysis' | 'assetData' | 'historyIndicator';
  reportName?: ReportNames;
  dataAnalysisName?: DataAnalysisName;
  // useLocalTimePeriod?: boolean;
  // localTimePeriod?: TimePeriod;
  // setLocalTimePeriod?: React.Dispatch<React.SetStateAction<TimePeriod>>;
}

export const DateFilter: React.FC<DateFilterProps> = ({
  fixedFilters,
  initialValue,
  breakpoint = supportedDevices.tablet,
  timePeriodState = 'treeHealth',
  reportName = 'healthAndIndicatorsPerLogicalElement',
  // useLocalTimePeriod = false,
  // localTimePeriod = null,
  // setLocalTimePeriod = null,
}) => {
  const { timePeriod } = useSelector(
    (state: AppState) => state[timePeriodState]
  );

  // if (useLocalTimePeriod) {
  //   timePeriod = localTimePeriod as TimePeriod;
  // }

  const dispatch = useDispatch();

  const location = useLocation();

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [selectedTime, setSelectedTime] = useState<TimeRange>({
    startTime: '00:00',
    endTime: '00:00',
  });

  const changeDate = useCallback(
    (time: TimePeriod) => {
      if (timePeriodState === 'treeHealth') {
        // if (setLocalTimePeriod != null) {
        //   setLocalTimePeriod(time);
        //   dispatch(Creators.getTreeHealthRequestWithTimePeriod(time));
        // } else {
        //   dispatch(Creators.setTreeHealthTimePeriod(time));
        //   dispatch(Creators.getTreeHealthRequest());
        // }
        dispatch(Creators.setTreeHealthTimePeriod(time));
        dispatch(Creators.getTreeHealthRequest());
      } else if (timePeriodState === 'reports')
        dispatch(Creators.getReportsRequest(time, reportName));
      else if (timePeriodState === 'dataAnalysis') {
        dispatch(Creators.setTimePeriod(time));
      } else if (timePeriodState === 'assetData') {
        dispatch(Creators.setAssetTimePeriod(time));
      } else if (timePeriodState === 'historyIndicator') {
      dispatch(Creators.setHistoryTimePeriod(time));
    }
    },
    [timePeriodState, dispatch, reportName]
  );

  //  Sets the timePeriod to 7 days in case it was 30 days
  useEffect(() => {
    if (
      location.pathname.startsWith('/admin/dashboard/ativo') &&
      String(timePeriod.startTime).endsWith('30d')
    )
      changeDate({ startTime: 'now-7d', endTime: 'now' });
  }, [location, dispatch, timePeriod.startTime, changeDate]);

  function selectNewDate(
    startTime: string | number | null,
    endTime: string | number | null
  ) {
    //  Resets current notification
    dispatch(Creators.setCurrentNotification(null));

    changeDate({ startTime, endTime });
  }

  function handleFixedPeriodClick(date: string) {
    var start = date === 'ATUAL' ? null : `now-${date}`;
    var end = date === 'ATUAL' ? null : 'now';
    if (date === 'yesterday') {
      start = 'yesterday';
      end = 'today';
    }
    selectNewDate(start, end);
  }

  function checkIfFilterIsSelected(item: string) {
    if (
      (!timePeriod.startTime && !timePeriod.endTime && item === 'ATUAL') ||
      (typeof timePeriod.startTime === typeof '' && item === String(timePeriod.startTime)?.substr(4)) ||
      (typeof timePeriod.startTime === typeof '' && item === String(timePeriod.startTime)) ||
      (typeof timePeriod.startTime === typeof 0 && item === 'CUSTOM')
    )
      return true;
    return false;
  }

  const showPicker = () => setIsPickerOpen(true);

  const hidePicker = () => setIsPickerOpen(false);

  function handleOpenPicker() {
    if (
      timePeriod.startTime &&
      timePeriod.endTime &&
      typeof timePeriod.startTime === 'number' &&
      typeof timePeriod.endTime === 'number'
    ) {
      setDateRange({
        startDate: toDate(timePeriod.startTime),
        endDate: toDate(timePeriod.endTime),
      });
      setSelectedTime({
        startTime: `${`0${getHours(timePeriod.startTime)}`.slice(
          -2
        )}:${`0${getMinutes(timePeriod.endTime)}`.slice(-2)}`,
        endTime: `${`0${getHours(timePeriod.endTime)}`.slice(
          -2
        )}:${`0${getMinutes(timePeriod.endTime)}`.slice(-2)}`,
      });
    }

    showPicker();
  }

  function handleCancelPress() {
    hidePicker();
    setDateRange(null);
  }

  function handleApplyPress() {
    hidePicker();

    if (dateRange) {
      const startDate = setHours(
        setMinutes(
          dateRange!.startDate!,
          Number(selectedTime.startTime.slice(-2))
        ),
        Number(selectedTime.startTime.slice(0, 2))
      );
      const endDate = setHours(
        setMinutes(dateRange!.endDate!, Number(selectedTime.endTime.slice(-2))),
        Number(selectedTime.endTime.slice(0, 2))
      );

      selectNewDate(startDate.getTime(), endDate.getTime());
    } else
      toastr.error('Período Inválido', 'Por favor selecione um período válido');
  }

  function handleChangeSelect(
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) {
    handleFixedPeriodClick(String(event.target.value));
  }

  const filters: DateFilterItem[] = [
    ...fixedFilters.map((filter) => {
      return {
        ...filter,
        onClick: () => handleFixedPeriodClick(filter.id),
      };
    }),
    {
      id: 'CUSTOM',
      content: <DateRangeIcon fontSize="large" />,
      onClick: handleOpenPicker,
      tooltip: 'Data personalizada',
    },
  ];

  return (
    <Media
      queries={{
        tablet: `${breakpoint}`,
      }}
    >
      {(matches) => (
        <>
          {matches.tablet && (
            <DesktopContainer data-testid="dateFilterContainer">
              {filters.map((item) => (
                <Tooltip
                  key={item.id}
                  title={<TooltipSpan>{item.tooltip}</TooltipSpan>}
                >
                  <Button
                    isSelected={checkIfFilterIsSelected(item.id)}
                    onClick={item.onClick}
                  >
                    {item.content}
                  </Button>
                </Tooltip>
              ))}
            </DesktopContainer>
          )}
          {!matches.tablet && (
            <MobileContainer>
              <Select
                value={
                  filters.find((item) => checkIfFilterIsSelected(item.id))!.id
                }
                onChange={handleChangeSelect}
                fullWidth
                input={
                  <InputBase
                    fullWidth
                    className="select-input"
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          onClick={filters[filters.length - 1].onClick}
                        >
                          <DateRangeIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                }
              >
                {filters
                  .filter((item) => item.id !== 'CUSTOM')
                  .map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.tooltip.toUpperCase()}
                    </MenuItem>
                  ))}
                {checkIfFilterIsSelected('CUSTOM') && (
                  <MenuItem value="CUSTOM">
                    {format(new Date(timePeriod.startTime!), 'dd/MM')}
                    <ArrowRightAlt />
                    {format(new Date(timePeriod.endTime!), 'dd/MM')}
                  </MenuItem>
                )}
              </Select>
            </MobileContainer>
          )}
          <CustomModal
            open={isPickerOpen}
            onClose={handleCancelPress}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={isPickerOpen}>
              <div className="outline-0">
                <div className="date-picker-container">
                  <DateRangePicker
                    open={isPickerOpen}
                    onChange={(range) => setDateRange(range)}
                    i18n={{
                      months: [
                        'Jan',
                        'Fev',
                        'Mar',
                        'Abr',
                        'Mai',
                        'Jun',
                        'Jul',
                        'Ago',
                        'Set',
                        'Out',
                        'Nov',
                        'Dez',
                      ],
                      days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                    }}
                    initialDateRange={
                      dateRange
                        ? {
                            startDate: dateRange.startDate,
                            endDate: dateRange.endDate,
                          }
                        : undefined
                    }
                    minDate={toDate(new Date('01/01/2019'))}
                    maxDate={toDate(Date.now())}
                    onCancelClick={handleCancelPress}
                    onApplyClick={handleApplyPress}
                    showSingleMonth={!matches.tablet}
                    selectedTime={selectedTime}
                    handleTimeClick={setSelectedTime}
                  />
                </div>
              </div>
            </Fade>
          </CustomModal>
        </>
      )}
    </Media>
  );
};
