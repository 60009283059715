import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HealthIndicator } from '../../../../../../../../components/charts/HealthIndicator';

import {
  CircularProgress,
  Grid,
  Slide,
  Slider,
  TextField,
} from '@material-ui/core';

import errorIcon from '../../../../../../../../assets/imgs/error-icon.png';
import { Table } from '../../../../../../../../components/charts/Table';
import { CollectedTag, CollectedTagArray } from '../../../../../../../../models/Reports';
import { Creators } from '../../../../../../../../store/actionCreators';
import { AppState } from '../../../../../../../../store/reducers';

import { Container } from './styles';
import { getCompanyEmails, useAuthStore } from '../../../../../../../../zustand/AuthStore';
import { RouteComponentProps } from 'react-router-dom';
import { CustomTab, CustomTabsContainer } from '../../../../../../../Notification/styles';
import { SearchField } from '../../../../../../../../components/SearchField';
import { TitleButton } from '../../../../../../../Dashboard/components/views/AssetView/components/Header/DesktopHeader/styles';
import { CustomDialog } from '../../../../../../../../components/CustomDialog';
import { useAlertStore } from '../../../../../../../../zustand/AlertStore';

export const CollectedTags: React.FC<RouteComponentProps> = ({

}) => {
  const { reports } = useSelector((state: AppState) => state);

  const user = useAuthStore((state) => state.user.uid);

  const { fetchCompanyAlertInfo, setSelectedAlertEmails, companyAlertInfo } = useAlertStore();

  const { collectedTagsInfo, collectedLogicalTags } = reports.data;
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const companyEmails = useAuthStore((state) => state.user.companyEmails);

  const [sliderValue, setSliderValue] = useState(0);
  const [numericInputValue, setNumericInputValue] = useState(0);

  const dispatch = useDispatch();

  const tree = useAuthStore((state) => state.user.tree);

  useEffect(() => {
    if (companyAlertInfo.minimumAvailability !== null) {
      const adjustedValue = companyAlertInfo.minimumAvailability * 100;
      setSliderValue(adjustedValue);
      setNumericInputValue(adjustedValue);
    }

    setSelectedEmails(companyAlertInfo.emailsList);
  }, [companyAlertInfo]);

  useEffect(() => {
    async function initial(){
      await getCompanyEmails()
      await fetchCompanyAlertInfo();
    }
    initial()
  }, []);

  useEffect(() => {
    if (tree) {

      dispatch(
        Creators.getTagRatioInfoRequest(
          tree.uuid
        )
      )

      if (currentTab == 0) {
        dispatch(
          Creators.getCollectedTagsInfoRequest(
            tree.uuid, 'physical'
          )
        )
      } else {
        dispatch(
          Creators.getCollectedTagsInfoRequest(
            tree.uuid, 'logical'
          )
        )
      }


    }
  }, [dispatch, tree, currentTab]);

  function handleTabChange(value) {
    setCurrentTab(value);
  }

  function buildHeaders() {
    return ['Ativo', 'Tags Necessárias', 'Tags Não Obtidas', 'Tags Monitoradas'];
  }

  function buildRows(data: CollectedTag[]) {
    if (!data) {
      return [];
    }

    const arrOfObjectsWithKeys = Object.entries(data).map(([key, value]) => ({ name: key, ...value }));
    return arrOfObjectsWithKeys;
  }

  function createTableRow(item: CollectedTagArray) {
    const necessaryTagsArray = item.necessaryTags || [];
    const obtainedTagsArray = item.obtainedTags || [];

    const necessaryTags: string = item.necessaryTags?.join(', ');
    const notObtainedTags = necessaryTagsArray.filter(
      (tag) => !obtainedTagsArray.includes(tag)
    );
    const monitoredTags: string = item.monitoredTags?.join(', ');
    const name: string = item.name.slice(0, -1).replaceAll('.', ' -> ');
    const notObtainedTagsString = notObtainedTags.join(', ');

    return [name, necessaryTags, notObtainedTagsString, monitoredTags];
  }

  const handleConfirmEmails = async () => {
    if (tree && selectedEmails.length > 0) {
      await setSelectedAlertEmails(selectedEmails, sliderValue);
      toggleAssetAlertsModal(false);
    }
  };

  function toggleAssetAlertsModal(isOpen: boolean) {
    setIsModalOpen(isOpen);
  }

  function buildLoaderAndError() {
    if (reports.error)
      return (
        <div className="error">
          <img src={errorIcon} alt="Ícone de erro" />
          <h3>Ocorreu um erro! Recarregue a página para tentar novamente.</h3>
        </div>
      );
    return (
      <div className="loader">
        <CircularProgress size={50} />
      </div>
    );
  }

  function handleEmailSearchChange(_: React.ChangeEvent<{}>, value: string[]) {
    setSelectedEmails(value);
  }

  function returnTable() {
    if (currentTab == 0) {
      return (
        <>
          {reports.error || reports.isLoading || !collectedTagsInfo ? (
            buildLoaderAndError()
          ) : (
            <>
              <div className="charts">
                <div className="table">
                  <Table
                    headers={buildHeaders()}
                    rows={buildRows(collectedTagsInfo[0])}
                    createRow={createTableRow}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )

    } else {
      return (
        <>
          {reports.error || reports.isLoading || !collectedLogicalTags ? (
            buildLoaderAndError()
          ) : (
            <>
              <div className="title-container">
                <h3>Relatório de Análise das Tags Lógicas Coletadas</h3>
              </div>
              <div className="charts">
                <div className="table">
                  <Table
                    headers={buildHeaders()}
                    rows={buildRows(collectedLogicalTags)}
                    createRow={createTableRow}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )
    }
  }

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    setNumericInputValue(newValue);
  };

  const handleNumericInputChange = (event) => {
    const value = event.target.value === '' ? 0 : Number(event.target.value);
    setNumericInputValue(value);
    setSliderValue(value);
  };

  return (
    <Container>

      <Slide in direction="left" timeout={500}>
        <div className="card">
          <div
            className="title-container"
            style={{ justifyContent: 'space-between', display: 'flex' }}
          >
            <h3>Relatório de Análise das Tags Coletadas</h3>
            <TitleButton
              variant="contained"
              onClick={() => toggleAssetAlertsModal(true)}
              data-testid="alertButton"
            >
              alertas
            </TitleButton>
            <div className="health-indicator-container">
              <HealthIndicator
                size={32}
                value={reports.data.tagRatio}
                fontSize={28}
                    healthType={"global"}
                width={185}
                    selectedIndicator={"Disponibilidade"}
              />
            </div>
          </div>
          <div className="tabs-container">
            <CustomTabsContainer
              value={currentTab}
              onChange={(_, value) => handleTabChange(value)}
              variant="fullWidth"
            >
              <CustomTab key={0} label="Ativos Físicos" />
              <CustomTab key={1} label="Ativos Lógicos" />
            </CustomTabsContainer>
          </div>
          {returnTable()}
        </div>




      </Slide>
      <CustomDialog
        open={isModalOpen}
        onClose={() => toggleAssetAlertsModal(false)}
        width={500}
        title="Destinatários dos Alertas"
        body={
            <div className="email-select-container">
            <SearchField
              onChange={handleEmailSearchChange}
              getOptionLabel={(option) => option}
              options={companyEmails || []}
              multiple
              placeholder="Destinatários..."
              value={selectedEmails}
            />
            <div className="slider-container">
            <h3 id="minimumAvailabilityHeader" style={{ marginTop: '10%' }}>Percentual mínimo de disponibilidade do ativo</h3>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                  <Slider
                    value={sliderValue}
                    onChange={handleSliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    value={numericInputValue}
                    onChange={handleNumericInputChange}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        }
        action={{
          onClick: handleConfirmEmails,
          label: 'Confirmar',
        }}
      />
    </Container>
  );
};
