import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Typography,
  Tooltip,
  Fade,
  MenuItem,
  Divider,
  Checkbox,
} from '@material-ui/core';
import { ArrowForward, ArrowDownward } from '@material-ui/icons';
import { HierarchyNode } from 'd3';

import sunburstOnIcon from '../../../../../../../../assets/new_icons/ic-switch-sunburst-select.svg';
import sunburstOffIcon from '../../../../../../../../assets/new_icons/ic-switch-sunburst-unselect.svg';
import treeMapOnIcon from '../../../../../../../../assets/new_icons/ic-switch-treemap-select.svg';
import treeMapOffIcon from '../../../../../../../../assets/new_icons/ic-switch-treemap-unselect.svg';
import { HealthIndicator } from '../../../../../../../../components/charts/HealthIndicator';
import {
  DateFilter,
  DateFilterItem,
} from '../../../../../../../../components/DateFilter';
import { SearchField } from '../../../../../../../../components/SearchField';
import { ToggleChartButton } from '../../../../../../../../components/ToggleChartButton';
import { NodeData } from '../../../../../../../../models/TreeMapNode';
import { Creators } from '../../../../../../../../store/actionCreators';
import { AppState } from '../../../../../../../../store/reducers';
import { SelectableIndicator } from '../../../../../../../../store/treeHealth/types';
import { HealthType, ChartType } from '../../../types';

import {
  CustomHeader,
  TooltipSpan,
  CustomMenu,
  Container,
  FilterBar,
  ActionButton,
  CriticalityOption,
  CriticalityConfirmationButton,
} from './styles';

interface HeaderProps {
  onFilterSelect: (node: HierarchyNode<NodeData>) => void;
  healthType: HealthType;
  onToggleChart: (chart: ChartType) => void;
  isSunburstVisible: boolean;
  isTreeMapVisible: boolean;
  handleLayerBlock: () => void;
  timeList: DateFilterItem[];
}

export const Header: React.FC<HeaderProps> = ({
  onFilterSelect,
  healthType,
  onToggleChart,
  isSunburstVisible,
  isTreeMapVisible,
  handleLayerBlock,
  timeList,
}) => {
  const treeHealth = useSelector((state: AppState) => state.treeHealth);

  const dispatch = useDispatch();

  const [dropdownItems, setDropdownItems] = useState<HierarchyNode<NodeData>[]>(
    []
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  function handleDropdownClose() {
    setAnchorEl(null);
  }

  function buildDropdownItems(currentNode: HierarchyNode<NodeData>) {
    const newDropdownItems: HierarchyNode<NodeData>[] = [];

    if (!currentNode.parent)
      newDropdownItems.push(
        treeHealth.sequence![treeHealth.sequence!.length - 1]
      );
    else
      currentNode.parent!.children!.forEach((node) => {
        newDropdownItems.push(node);
      });

    setDropdownItems(newDropdownItems);
    return newDropdownItems.length;
  }

  const handleIndicatorSelection = (
    e: React.ChangeEvent,
    value: SelectableIndicator
  ) => {
    dispatch(Creators.setSelectedIndicator(value));
    dispatch(Creators.getTreeHealthRequest());
  };

  const handleDropdownClick = (
    event: React.MouseEvent<HTMLElement>,
    node: HierarchyNode<NodeData>
  ) => {
    const length = buildDropdownItems(node);
    if (length > 1) setAnchorEl(event.currentTarget);
  };

  function handleDropdownItemClick(node: HierarchyNode<NodeData>) {
    onFilterSelect(node);
    handleDropdownClose();
  }

  function handleSearchBarChange(_: any, node: HierarchyNode<NodeData> | null) {
    if (node) onFilterSelect(node);
    else onFilterSelect(treeHealth.sequence![0]);
  }

  const getInitialCriticalLevelsSize = () => {

    if (treeHealth.selectedCriticality != null) {
      const initialLevels = Array.from({ length: 7 }, (_, index) => ({
        index,
        checked: treeHealth.selectedCriticality.includes(index)
      }));
      return initialLevels;
    }
  };
  
  const [criticalLevelsSize, setCriticalLevelsSize] = useState(getInitialCriticalLevelsSize());
  
  useEffect(() => {
    localStorage.setItem('criticalLevelsSize', JSON.stringify(criticalLevelsSize));
  }, [criticalLevelsSize]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const criticalLevels = ["Sem Criticidade", "Criticidade 1", "Criticidade 2", "Criticidade 3", "Criticidade 4", "Criticidade 5", "Confirmar"];
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCriticality = (event, value) => {
    if (value !== null) {
      const index = value;
      const newCriticalLevelsSize = [...criticalLevelsSize];
      newCriticalLevelsSize[index] = {
        ...newCriticalLevelsSize[index],
        checked: !newCriticalLevelsSize[index].checked,
      };
      setCriticalLevelsSize(newCriticalLevelsSize);
      setSelectedOptions((prev) => {
        if (prev.includes(value)) {
          return prev.filter((item) => item !== value);
        } else {
          return [...prev, value];
        }
      });
    }
  };

  function handleCriticalityConfirm() {
    const checkedIndexes = [];
    criticalLevelsSize.forEach((item, index) => {
      if (item.checked && index < criticalLevelsSize.length - 1) {
        checkedIndexes.push(index);
      }
    });

    if (checkedIndexes.length !== 0) {
      dispatch(Creators.setSelectedCriticality(checkedIndexes));
      dispatch(Creators.getTreeHealthRequest());
    }
  }

  const handleOptionClick = (index) => (event) => {
    event.stopPropagation();
    const newCriticalLevelsSize = [...criticalLevelsSize];
    newCriticalLevelsSize[index] = {
      ...newCriticalLevelsSize[index],
      checked: !newCriticalLevelsSize[index].checked,
    };
    setCriticalLevelsSize(newCriticalLevelsSize);
  };
  return (
    <Container>
      <CustomHeader>
        <div className="info-container">
          <div className="breadcrumb-container">
            {treeHealth.sequence!.map((node, index, array) =>
              array.length - 1 === index ? (
                <React.Fragment key={node.data.identifier}>
                  <Fade in>
                    <Tooltip
                      title={<TooltipSpan>{node.data.name}</TooltipSpan>}
                      enterDelay={500}
                    >
                      <Button
                        color="secondary"
                        variant="contained"
                        className="gradient"
                        onClick={(event) =>
                          index === 0
                            ? undefined
                            : handleDropdownClick(
                                event,
                                node as HierarchyNode<NodeData>
                              )
                        }
                        aria-controls="dropdown-menu"
                        aria-haspopup="true"
                      >
                        <Typography noWrap component="span" variant="button">
                          {node.data.name}
                        </Typography>
                        {index !== 0 && <ArrowDownward />}
                      </Button>
                    </Tooltip>
                  </Fade>
                  <CustomMenu
                    id="dropdown-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleDropdownClose}
                  >
                    {dropdownItems.map((n) => (
                      <MenuItem
                        key={n.data.identifier}
                        onClick={() => handleDropdownItemClick(n)}
                        selected={
                          n.data.identifier ===
                          treeHealth.selectedNode.data.identifier
                        }
                      >
                        {n.data.name}
                      </MenuItem>
                    ))}
                  </CustomMenu>
                </React.Fragment>
              ) : (
                <Fade in key={node.data.identifier}>
                  <Tooltip
                    title={<TooltipSpan>{node.data.name}</TooltipSpan>}
                    enterDelay={500}
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => onFilterSelect(node)}
                    >
                      <Typography noWrap component="span" variant="button">
                        {node.data.name}
                      </Typography>
                      <ArrowForward />
                    </Button>
                  </Tooltip>
                </Fade>
              )
            )}
          </div>
          <div className="title-actions-container">
            <h1>{treeHealth.selectedNode.data.name}</h1>
            {treeHealth.timePeriod?.startTime === null && (
              <ActionButton variant="contained" onClick={handleLayerBlock}>
                {treeHealth.selectedNode.data.blocked
                  ? 'Desbloquear'
                  : 'Bloquear'}
              </ActionButton>
            )}
          </div>
        </div>
        <div className="health-indicator-container">
          <HealthIndicator
            size={33}
            value={treeHealth.selectedNode.data.value!}
            fontSize={32}
            healthType={healthType}
            width={185}
            selectedIndicator={treeHealth.selectedIndicator.name}
          />
        </div>
      </CustomHeader>
      <Divider variant="middle" />
      <FilterBar>
        <div className="search-container">
          <SearchField
            options={treeHealth.sequence![0].descendants()}
            getOptionLabel={(option: HierarchyNode<NodeData>) =>
              option.data.name
            }
            onChange={handleSearchBarChange}
          />
        </div>
        <div className="indicators-selector-container">
          <SearchField
            getOptionLabel={(option: SelectableIndicator) => option.name}
            options={treeHealth.selectableIndicatorsOptions}
            onChange={handleIndicatorSelection}
            showSearchIcon={false}
            placeholder="Selecionar Indicador"
            value={treeHealth.selectedIndicator}
            getOptionSelected={(
              option: SelectableIndicator,
              value: SelectableIndicator
            ) => option.uuid === value.uuid}
            disableClearable
          />
        </div>
        <div className="indicators-selector-container">
        <SearchField
          showSearchIcon={false}
          disableClearable={true}
          getOptionLabel={(option) =>
            "Criticidade"
          }
          options={criticalLevelsSize.map((_, index) => index)}
          value={treeHealth.selectedCriticality}
          onChange={(event, value) => {
            handleChangeCriticality(event, value);
          }}
          placeholder="Criticidade"
          renderOption={(option) => {
            if (option !== criticalLevelsSize.length - 1) {
              return (
                <CriticalityOption onClick={handleOptionClick(option)}>
                <Checkbox
                  color="default"
                  checked={criticalLevelsSize[option].checked}
                  onChange={(e) => e.stopPropagation()}
                />
                <Typography>{criticalLevels[option]}</Typography>
              </CriticalityOption>
              );
            } else {
              return (
                <CriticalityConfirmationButton
                variant="contained"
                onClick={handleCriticalityConfirm}
              >
                {"Confirmar"}
              </CriticalityConfirmationButton>
              );
            }
          }}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
        />

        </div>
        <div className="indicator-and-toggles">
          <DateFilter fixedFilters={timeList} initialValue={treeHealth.data.name === 'Samarco' ? "yesterday" : "ATUAL"} /> 
          <div className="toggle-charts">
            <ToggleChartButton
              onIcon={sunburstOnIcon}
              offIcon={sunburstOffIcon}
              alt="Mostrar ou esconder gráfico sunburst"
              isSelected={isSunburstVisible}
              onToggle={() => onToggleChart('sunburst')}
            />
            <ToggleChartButton
              onIcon={treeMapOnIcon}
              offIcon={treeMapOffIcon}
              alt="Mostrar ou esconder gráfico sunburst"
              isSelected={isTreeMapVisible}
              onToggle={() => onToggleChart('treemap')}
              iconSize={45}
            />
          </div>
        </div>
      </FilterBar>
    </Container>
  );
};
