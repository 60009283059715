import { TimePeriod } from '../components/DateFilter';

const baseURL = process.env.REACT_APP_STAGE == "production" ?
  process.env.REACT_APP_GRAFANA_PROD : process.env.REACT_APP_GRAFANA_DEV;

export const GrafanaUrls = {
  getHealthPanelUrl: (assetid: string, period: TimePeriod) =>
    `${baseURL}/d-solo/4hcv1zymk/actus-graphs?refresh=10s&orgId=2&panelId=3&theme=light&var-identifier=${assetid}&from=${period.startTime}&to=${period.endTime}`,
  getHealthHistoryPanelUrl: (assetid: string, period: TimePeriod) =>
    `${baseURL}/d-solo/4hcv1zymk/actus-graphs?refresh=10s&orgId=2&panelId=4&theme=light&var-identifier=${assetid}&from=${period.startTime}&to=${period.endTime}`,
  getTagsPanelUrl: (
    timePeriod: TimePeriod,
    identifier: string,
    tags: string[]
  ) =>
    `${baseURL}/d-solo/4hcv1zymk/actus-graphs?refresh=10s&orgId=2&panelId=2&theme=light&from=${timePeriod.startTime
    }&to=${timePeriod.endTime}&var-identifier=${identifier}${tags.length ? `&var-tags=${tags.join('&var-tags=')}` : ''
    }`,
  FFT_PANEL_URL:
    `${baseURL}/d/d9l2E2zMz/fft?orgId=2&refresh=10s&theme=light&kiosk`,
  LOGICAL_HEALTH_PANEL_URL:
    `${baseURL}/d-solo/oCRT3mPWk/actus-graphs-logical-asset?orgId=2&refresh=10s&theme=light&panelId=3`,
  LOGICAL_DATACARD_SPPV_PANEL_URL:
    `${baseURL}/d-solo/oCRT3mPWk/actus-graphs-logical-asset?orgId=2&refresh=10s&theme=light&panelId=2`,
  LOGICAL_DATACARD_MV_PANEL_URL:
    `${baseURL}/d-solo/oCRT3mPWk/actus-graphs-logical-asset?orgId=2&refresh=10s&theme=light&panelId=5`,
  LOGICAL_DATACARD_STD_PV_PANEL_URL:
    `${baseURL}/d-solo/oCRT3mPWk/actus-graphs-logical-asset?orgId=2&refresh=10s&theme=light&panelId=9`,
  LOGICAL_DATACARD_STD_MV_PANEL_URL:
    `${baseURL}/d-solo/oCRT3mPWk/actus-graphs-logical-asset?orgId=2&refresh=10s&theme=light&panelId=7`,
  LOGICAL_DATACARD_KP_PANEL_URL:
    `${baseURL}/d-solo/oCRT3mPWk/actus-graphs-logical-asset?orgId=2&refresh=10s&theme=light&panelId=11`,
  LOGICAL_DATACARD_KI_PANEL_URL:
    `${baseURL}/d-solo/oCRT3mPWk/actus-graphs-logical-asset?orgId=2&refresh=10s&theme=light&panelId=13`,
  LOGICAL_DATACARD_KD_PANEL_URL:
    `${baseURL}/d-solo/oCRT3mPWk/actus-graphs-logical-asset?orgId=2&refresh=10s&theme=light&panelId=15`,
  LOGICAL_DATACARD_MODE_PANEL_URL:
    `${baseURL}//d-solo/oCRT3mPWk/actus-graphs-logical-asset?orgId=2&refresh=10s&theme=light&panelId=16`,
  LOGICAL_ALL_TAGS_PANEL_URL:
    `${baseURL}/d-solo/oCRT3mPWk/actus-graphs-logical-asset?orgId=2&refresh=10s&theme=light&panelId=17`,
  LOGICAL_ASSET_INFORMATION_DASHBOARD_URL:
    `${baseURL}/d/J3sl-vCWk/actus-graphs-logical-asset-info-v2?orgId=2&refresh=10s&kiosk&theme=light`,
  getLogicalAssetDataAnalysisDashboardUrl: (
    timePeriod: TimePeriod,
    identifier: string,
    tagX: string,
    tagY: string,
    assetModel: string
  ) => {
    let baseUrl;
    switch (assetModel.toUpperCase()) {
      case 'CS2998': //Gerdau OB
      case 'CS2999':
      case 'CS3000':
      case 'CS3001':
      case 'CS3002':
        baseUrl = `${baseURL}/d/pNkq-vCZk/actus-graphs-logical-asset-data-analysis-v2?orgId=2&refresh=10s&theme=light&kiosk`;
        break;

      case 'FB41': //Gerdau BC
        baseUrl = `${baseURL}/d/kymwSXtGk/actus-graphs-logical-asset-data-analysis-siemens-fb41?orgId=2&refresh=10s&theme=light&kiosk`;
        break;

      case '140CPU67261': //CMOC
        baseUrl = `${baseURL}/d/-CbBUjTMz/actus-graphs-logical-asset-data-analysis-schneider-140cpu67261?orgId=2&refresh=10s&theme=light&kiosk`;
        break;

      case 'PID Enhanced': //Anglo
        baseUrl = `${baseURL}/d/S4kIQ2-Mz/actus-graphs-logical-asset-data-analysis-rockwell-pidenhanced?orgId=2&refresh=10s&theme=light&kiosk`;
        break;

      case "STP 503": //Bayer 
        baseUrl = `${baseURL}/d/PDdSxDeGz/actus-graphs-logical-asset-data-analysis-mip-bayer-stp503?orgId=2&refresh=10s&theme=light&kiosk`;
        break;

      case "FB41(CONT_C)": //Bayer UDI
        baseUrl = "https://api.nexum.digital/grafana/d/CsUxcnT7z/actus-graphs-logical-asset-data-analysis-siemens-fb41-cont_c?orgId=2&refresh=10s&theme=light&kiosk";
        break;

      default:
        baseUrl = `${baseURL}/d/pNkq-vCZk/actus-graphs-logical-asset-data-analysis-v2?orgId=2&refresh=10s&theme=light&kiosk`;
    }

    return `${baseUrl}&from=${timePeriod.startTime}&to=${timePeriod.endTime}&var-identifier=${identifier}&var-Tag_X=${tagX}&var-Tag_Y=${tagY}`;
  },
  getLogicalAssetIndicatorValueUrl: (
    assetIdentifier: string,
    indicatorDatabaseName: string,
    period: TimePeriod
  ) =>
    `${baseURL}/d-solo/I8rHQrOGk/actus-graphs-logical-asset-info-v3?orgId=2&refresh=10s&panelId=20&theme=light&var-identifier=${assetIdentifier}&var-tag=${indicatorDatabaseName}&from=${period.startTime}&to=${period.endTime}`,
  getLogicalAssetRadarChartUrl: (assetIdentifier: string, period: TimePeriod) =>
    `${baseURL}/d-solo/I8rHQrOGk/actus-graphs-logical-asset-info-v3?orgId=2&refresh=10s&theme=light&from=${period.startTime}&to=${period.endTime}&var-identifier=${assetIdentifier}&panelId=6`,

  getTagsComparisonChartUrl: (tags: string[], period: TimePeriod) =>
    `${baseURL}/d-solo/nrtV5WtGk/tags-comparison?orgId=2&theme=light&from=${period.startTime
    }&to=${period.endTime}&${tags
      .map((tag, index) => `var-tag${index + 1}=${tag}`)
      .join('&')}&panelId=2`,
};
